import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import useLocalStorage from "use-local-storage";
import "./Main.scss";
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'


const Main = () => {

    const defaultLight = window.matchMedia('(prefers-color-scheme: light)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultLight ? 'dark' : 'light');

    const switchTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
    }

    return (
        <div className="main" data-theme={theme}>

            
            <div className="mid-section">

                <div className="header">
                    <button className="switcher" onClick={switchTheme}>
                        {theme === 'light' ? <FontAwesomeIcon size={'2x'} icon={faMoon} className="icon"/> : <FontAwesomeIcon size={'2x'} icon={faSun} className="icon"/>}
                    </button>
                    {/* <button className="home-button">Home</button> */}
                </div>
                <div className="section">
                    <div className="section-title">
                        <p>Intro</p>
                    </div>
                    <div className="section-text">
                        <p>
                            {"Hi! :) I'm Jasper."}
                        </p>
                        <p>
                            {"I'm a Quantitative Researcher/Developer at a Proprietary Trading Firm."}
                            {" Previously, I've worked at "}
                            <a className="styled-link citadel hoverable" href="https://www.citadel.com/" target="_blank" rel="noreferrer">{"Citadel"}</a>
                            {", "}
                            <a className="styled-link meta hoverable" href="https://ai.meta.com/" target="_blank" rel="noreferrer">{"Meta AI"}</a>
                            {", "}
                            <a className="styled-link nuro hoverable" href="https://nuro.ai/" target="_blank" rel="noreferrer">{"Nuro"}</a>
                            {", and "}
                            <a className="styled-link tesla hoverable" href="https://tesla.com/" target="_blank" rel="noreferrer">{"Tesla"}</a>
                            {"."}

                        </p>
                        <p>
                            {"I graduated from the University of "}
                            <a className="styled-link toronto hoverable" href="https://www.utoronto.ca/" target="_blank" rel="noreferrer">{"Toronto"}</a>
                            {" specializing in Computer Science with a focus in Artificial Intelligence and minoring in Mathematics and Statistics."}
                        
                        </p>
                        <p>
                            {"Happy to talk about food, music, "}
                            <a className="styled-link cat hoverable" href="https://jasperjschen.com/cat.jpg" target="_blank" rel="noreferrer">{"my cat"}</a>
                            {", and quantitative finance."}
                        </p>
                    </div>
                </div>
                <div className="section">
                    <div className="section-title">
                        <p>Links</p>
                    </div>
                    <div className="section-text">
                        <p>
                            <div className="links-section">
                                <a className="link" href="https://www.linkedin.com/in/jasperjschen/" target="_blank" rel="noreferrer">LinkedIn</a>{`   `}
                                <a className="link" href="https://github.com/jasperjschen/" target="_blank" rel="noreferrer">GitHub</a>{`   `}
                                <a className="link" href="mailto:jasperjschen@outlook.com" target="_blank" rel="noreferrer">Email</a>
                            </div>

                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
